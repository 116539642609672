import { Inject, Injectable } from '@angular/core';
import {
  DS_APP_WRAPPER_CONFIG,
  DsAppWrapperConfig,
} from '@design-system/feature/app-wrapper-v2';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  currentEnvironment: string;
  private readonly baseEquipmentUrlTest =
    'https://equipment-test.palfinger.com/';
  private readonly baseEquipmentUrlProd = 'https://equipment.palfinger.com/';
  private readonly baseApiUrlTest = 'https://palfinger-test.azure-api.net';
  private readonly baseApiUrlProd = 'https://palfinger.azure-api.net';
  private readonly baseApiUrlQa = 'https://palfinger-qa.azure-api.net';
  private readonly baseDashboardUrlTest = 'https://paldesk-test.palfinger.com/';
  private readonly baseDashboardUrlProd = 'https://paldesk.palfinger.com/';

  private readonly environmentUrls = {
    dev: {
      equipmentUrl: this.baseEquipmentUrlTest,
      apiBasePath: this.baseApiUrlTest,
      dashboardUrl: this.baseDashboardUrlTest,
    },
    test: {
      equipmentUrl: this.baseEquipmentUrlTest,
      apiBasePath: this.baseApiUrlTest,
      dashboardUrl: this.baseDashboardUrlTest,
    },
    qa: {
      equipmentUrl: this.baseEquipmentUrlTest,
      apiBasePath: this.baseApiUrlQa,
      dashboardUrl: this.baseDashboardUrlTest,
    },
    prod: {
      equipmentUrl: this.baseEquipmentUrlProd,
      apiBasePath: this.baseApiUrlProd,
      dashboardUrl: this.baseDashboardUrlProd,
    },
  };

  constructor(
    @Inject(DS_APP_WRAPPER_CONFIG) private _config: DsAppWrapperConfig,
  ) {
    this.currentEnvironment = this._config.environment;
  }

  getEquipmentScreenUrl(): string {
    return this.getEnvironmentConfig().equipmentUrl;
  }

  getApiBasePath(): string {
    return this.getEnvironmentConfig().apiBasePath;
  }

  getDashboardUrl(): string {
    return this.getEnvironmentConfig().dashboardUrl;
  }

  private getEnvironmentConfig() {
    const envConfig = this.environmentUrls[this.currentEnvironment];
    if (!envConfig) {
      throw new Error(`Unknown environment: ${this.currentEnvironment}`);
    }
    return envConfig;
  }
}
